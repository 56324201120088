import { MenuEntry } from '@pancakeswap-libs/uikit'

// const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) =>

export type ConfigMenuItemsType = MenuEntry & { TranslateString?: any }

const config:(t: any) => ConfigMenuItemsType[]= (t:any) =>[
  {
    label: t(11101,'Bridge'),
    icon: 'ToyIcon2',
    href: 'https://benswap.cash/',
  },
  {
    label: t(10064,'Trade'),
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: t(10010,'Exchange'),
        href: '/swap'
      },
      {
        label: t(10011,'Liquidity'),
        href: '/pool',
        children:[
          '/create',
          '/find',
          '/add',
          '/add/:currencyIdA',
          '/add/:currencyIdA/:currencyIdB',
          '/remove/v1/:address',
          '/remove/:tokens',
          '/remove/:currencyIdA/:currencyIdB'
        ]
      },
      {
        label: t(11037,'Aggregator'),
        href: '/dexaggregator'
      },
      {
        label: t(11141,'Aggregator Pro'),
        href: '/aggregatorpro'
      }
    ]
  },
  {
    label: t(13098,'All Features (Under Maintenance)'),
    icon: 'HomeIcon',
    href: 'https://full.benswap.cash'
  },
  {
    label: t(10059,"More"),
    icon: 'MoreIcon',
    items: [
      {
        label: t(10060,"Github"),
        href: "https://github.com/BenTokenFinance",
      },
      {
        label: t(10061,"Docs"),
        href: "https://docs.benswap.cash",
      }
    ],
  },
]

export default config
